<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Multiselect from "vue-multiselect";
import { bankTypeEnum } from "@/components/enums.js";
import format from "date-fns/format";
import TourTable from "../../../components/reservation/TourTable";
import HotelTable from "../../../components/reservation/HotelTable";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Gelir Düzenle",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect, HotelTable, TourTable},
  data() {
    return {
      title: "Gelir Düzenle",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Gelir Listesi",
          href: "/revenue"
        },
        {
          text: "Gelir Düzenle",
          active: true,
        },
      ],
      // Value
      type: "",
      value: "",
      balance: "",
      currency: "",
      date: "",
      bank_type: "",
      pay_type: "",
      comment: "",
      // Value End
      payType: [],
      bankTypeEnum,
      values: [],
      datascurrency: [],
      expenseLabel: "Gelir: ",
      expenseType: [],
      employee: [],
      reservation: [],
    };
  },
  created() {
    this.getAllCurrency(), this.getAllBanks(), this.getAllExpense(), this.get()
  },
  watch: {
    type(value) {
      if (value.name == "Personel") {
        this.expenseLabel = "Gider: Personel"
        this.getData("/employee/getAll", "values");
      }
      if (value.name == "Acenta") {
        this.expenseLabel = "Gider: Acenta"
        this.getData("/agency/getAll", "values");
      }
      if (value.name == "Cari") {
        this.expenseLabel = "Gider: Cari"
        this.getData("/current-account/getAll", "values");
      }
    }
  },
  methods: {
    getAllCurrency() {
      this.getData("/exchange/getAll", "datascurrency");
    },
    getAllBanks() {
      this.getData("/banks/getAll", "payType");
    },
    getAllExpense() {
      this.getData("/incomeexpensetype/getAll/1", "expenseType");
    },
    submit() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + "/revenue/" + itemId;

      const data = {
        type: this.type.id,
        revenue_value: this.value.id,
        balance: this.balance,
        currency: this.currency.id,
        date: this.date,
        bank_type: this.bank_type.value,
        pay_type: this.pay_type.id,
        comment: this.comment,
      }
      axios
        .put(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/revenue");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getData(endpoint, dataKey, callback = null) {
      axios
        .get(process.env.VUE_APP_BASEURL + endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this[dataKey] = response.data?.data;
          if (callback) {
            callback(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    get() {
      const itemId = this.$route.params.id;
      axios
        .get(process.env.VUE_APP_BASEURL + "/revenue/" + itemId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.value = JSON.parse(response.data?.data.value);
          this.type = response.data?.data.type;
          this.balance = response.data?.data.balance;
          this.currency = response.data?.data.currency;
          this.date = new Date(response.data?.data.date);
          this.bank_type = response.data?.data.bank_type;
          this.pay_type = response.data?.data.pay_type;
          this.comment = response.data?.data.comment;
          this.expenseLabel = "Gelir: " + response.data?.data.type.name
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          if(this.value?.reservation_id > 0){
            this.getReservation(this.value?.reservation_id);
          }

        });
    },
    formatDate(date) {
      return format(date, "dd/MM/yyyy");
    },
    getReservation($id) {
      const itemId = $id
      const api_url = process.env.VUE_APP_BASEURL + `/reservation/${itemId}`;
      if(itemId != 0){
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.reservation = response.data?.data
          const responseData = response.data?.data
          // Acenta bilgisi
          const agencyData = [
            {
              id: responseData.agency.id,
              type: 1, // Acenta tipi
              reservation_id: responseData.id,
              name: "Acenta: " + responseData.agency.company_name,
            }
          ]

          this.values = agencyData;
        })
        .catch((error) => {
          console.error(error);
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="row">

                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Gelir Türü</label>
                    <multiselect v-model="type" :options="expenseType" label="name">
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">{{ expenseLabel }}</label>
                    <multiselect v-model="value" :options="values" label="name">
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Ödeme Tarihi</label>
                    <input type="date" v-model="date" class="form-control">
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Tutar</label>
                    <input v-model="balance" type="double" class="form-control" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Kur</label>
                    <multiselect v-model="currency" :options="datascurrency" label="name">
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Ödeme Türü</label>
                    <multiselect v-model="bank_type" :options="bankTypeEnum" label="text">
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <label for="brand_name">Ödeme Yöntemi</label>
                    <multiselect v-model="pay_type" :options="payType" label="label">
                    </multiselect>
                  </div>
                </div>

                <div class="col-md-8">
                  <div class="form-group mb-3">
                    <label for="brand_name">Açıklama</label>
                    <input v-model="comment" type="text" class="form-control" />
                  </div>
                </div>

              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary px-4">Kaydet</button>
              </div>
            </form>

          </div>
        </div>
        <div class="mt-3" v-if="this.type.name === 'Rezervasyon'">
          <aside v-if="reservation?.reservations.length > 0">
            <HotelTable :reservations="reservation.reservations" :editProps="editProps" />
          </aside>

          <aside v-if="reservation?.tourRoutes.length > 0">
            <TourTable :tourRoutes="reservation.tourRoutes" :editTourProps="editTourProps" />
          </aside>
        </div>
      </div>
    </div>
  </Layout>
</template>